import * as React from 'react';

const Footer = () => (
  <footer className={'page-micro footer'}>
    <small>
      <span>© {new Date().getFullYear()} Anna Matsumoto</span>
    </small>
  </footer>
);

export default Footer;