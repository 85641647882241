import * as React from 'react';
import { ReactNode } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { defaultLangKey, Language } from '../data/languages';
import Header from './header';
import Footer from './footer';
import './layout.scss';

type Props = {
  children: ReactNode;
  lang?: Language;
  pathnameWithoutLang: string;
  location: Location;
};

const Layout = ({ children, lang, pathnameWithoutLang, location }: Props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title {
            ja
            en
          }
        }
      }
    }
  `);

  function getPageUniqueClassName() {
    const langPrefix = lang === defaultLangKey ? null : lang;
    const pathname = langPrefix
      ? location.pathname.replace(`/${lang}`, '')
      : location.pathname;
    return pathname.split('/')[1] || 'top';
  }

  return (
    <div className={getPageUniqueClassName()}>
      <Header
        siteTitle={data.site.siteMetadata.title[lang || defaultLangKey]}
        lang={lang || defaultLangKey}
        pathnameWithoutLang={pathnameWithoutLang}
      />
      <div>
        <main className={'main'}>{children}</main>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
