import * as React from 'react';
import { Link } from '../utils/link';
import cn from 'classnames';
import { getPathWithLanguage, Language, langs } from '../data/languages';
import { models } from '../../models';

type Props = {
  siteTitle: string;
  lang: Language;
  pathnameWithoutLang?: string;
};

const Header = ({ lang, pathnameWithoutLang }: Props) => {
  return (
    <header className="header">
      <Link className="header__title" to={getPathWithLanguage(lang)}>
        <h2 className="header__title__text header__title__text--ja">
          <span>松本 杏菜</span>
        </h2>
        <span className="hide-mobile">/</span>
        <h1 className="header__title__text header__title__text--en">
          <span>ANNA MATSUMOTO</span>
        </h1>
      </Link>
      <nav className="header__nav">
        <ul>
          <li className="header__nav__item">
            <Link
              to={getPathWithLanguage(lang, models.dynamic.about.path)}
              activeClassName="active"
            >
              ME
            </Link>
          </li>
          <li className="header__nav__item">
            <Link
              to={getPathWithLanguage(lang, models.static?.works?.path || '')}
              activeClassName="active"
            >
              WORKS
            </Link>
          </li>
          {/*<li className="header__nav__item">*/}
          {/*  <Link*/}
          {/*    to={getPathWithLanguage(*/}
          {/*      lang,*/}
          {/*      models.static?.blogPosts?.path || ''*/}
          {/*    )}*/}
          {/*    activeClassName="active"*/}
          {/*  >*/}
          {/*    NOTES*/}
          {/*  </Link>*/}
          {/*</li>*/}
        </ul>
      </nav>
      <div className="header__lang">
        <ul>
          {langs.map(langItem => (
            <li
              className={cn({
                header__lang__item: true,
                active: lang === langItem,
              })}
              key={langItem}
            >
              <Link
                to={`${getPathWithLanguage(
                  langItem,
                  pathnameWithoutLang || ''
                )}`}
                className={cn({ active: lang === langItem })}
              >
                {langItem.toUpperCase()}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </header>
  );
};

export default Header;
