import * as React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { Language } from '../data/languages';

type Props = {
  description: string;
  lang: Language;
  meta?: HTMLMetaElement[];
  title: string;
  featuredImage?: string;
};

function SEO({ description, lang, meta, title, featuredImage }: Props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title {
              en
              ja
            }
            description {
              en
              ja
            }
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description[lang];

  let imageMeta: any[] = [];
  if (featuredImage) {
    imageMeta.push(
      {
        name: 'twitter:image',
        content: featuredImage,
      },
      {
        name: 'og:image',
        content: featuredImage,
      }
    );
  }

  const titleFormat = title
    ? `${title} | ${site.siteMetadata.title[lang]}`
    : site.siteMetadata.title[lang];

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={titleFormat}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: titleFormat,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: titleFormat,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(imageMeta)
        .concat(meta ?? [])}
    />
  );
}

export default SEO;
